import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import './views/admin/assets/fontawesome/css/all.css';
import 'sweetalert2/dist/sweetalert2.min.css';
import 'bootstrap/dist/js/bootstrap.bundle';
import 'datatables.net-bs5';
import 'datatables.net-buttons-bs5';
import 'datatables.net-responsive-bs5';
import VueSweetalert2 from 'vue-sweetalert2';
import store from './store'
import VueTheMask from 'vue-the-mask'
import './registerServiceWorker'

const app = createApp(App)

app.use(router)
app.use(VueSweetalert2);
app.use(store)
app.use(VueTheMask)
app.mount('#app')
