import { createStore } from 'vuex'

const store =  createStore({
  state: {
    user:JSON.parse(localStorage.getItem("user")),
    isAuthenticated:false
  },
  getters: {
    getToken: state => state.token,
    isAuthenticated: state => state.isAuthenticated,
    getUser: state => state.user,
  },
  mutations: {
    login(state, { user }) {
      state.isAuthenticated = true;
      state.user = JSON.parse(localStorage.getItem("user"));
      state.authUser = user;
      localStorage.setItem("user", JSON.stringify(user));
    },
    logout(state) {
      state.isAuthenticated = false;
      state.user = null;
    },
    updateAvatar(state, avatar) {
      // Atualiza o avatar no estado do Vuex
      if (state.user) {
        state.user.avatar = avatar;
        // Atualiza o avatar no localStorage também
        localStorage.setItem("user", JSON.stringify(state.user));
      }
    }
  },  
  actions: {
  },
  modules: {
  }
})

export default store;